.bg-grey {
  border: 1px dashed #ccc;
  margin-top: 15px !important;
}

.transactiondetailModal {
    zoom: 85% !important;
}

.transactiondetailModal .modal-title {
  text-transform: capitalize !important;
  color: #fff;
}

.transactiondetailModal .modal-title span {
  text-transform: lowercase;
}

#transactiondetailModal .modal-header {
    background-color: rgba(55, 123, 186, 100%);
    color: #fff !important;
}

.transactiondetailModal .modal-content {
    line-height: 25px;
}

.transaction-detail-data ul {
  list-style-type: none;
  padding:0px;
}
.transaction-detail-data ul li h5 {
  min-width: 100px;
}
.transaction-detail-data ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.heading {
  font-size: 16px;
  font-weight: 900 !important;
}